import { useState } from 'react';
export type ModalProps = {
  show: boolean;
  handleShow: () => void;
  handleClose: () => void;
};

/**
 * Custom hook for managing modal state and actions.
 *
 * @returns {ModalProps} Object containing modal state and action functions.
 */
const useModal = () => {
  /**
   * State to control the visibility of the modal.
   */
  const [show, setShow] = useState(false);

  /**
   * Function to show the modal.
   */
  const handleShow = () => setShow(true);

  /**
   * Function to close the modal.
   */
  const handleClose = () => setShow(false);

  /**
   * Object representing the modal properties and action functions.
   */
  const modal: ModalProps = { show, handleShow, handleClose };

  /**
   * Return the modal object.
   */
  return modal;
};

export default useModal;
