import React from 'react';
import BarChart from './content_types/BarChart';
import PieChart from './content_types/PieChart';
import LineChart from './content_types/LineChart';
import AreaChart from './content_types/AreaChart';
import Section from './content_types/Section';
import Datatable from "./content_types/Datatable";
import StoryCard from "./content_types/StoryCard";

interface Props {
    contentType: string;
    contentIndex: number;
}

const DashboardCardContent: React.FC<Props> = ({contentType, contentIndex}) => {
    const content: any = {
        bar: <BarChart/>,
        pie: <PieChart/>,
        line: <LineChart/>,
        area: <AreaChart/>,
        section: <Section index={contentIndex}/>,
        table: <Datatable/>,
        card: <StoryCard/>

    };
    return content[contentType] ?? <></>;
};

export default DashboardCardContent;
