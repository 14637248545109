import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useData } from '../context/AppProvider';
import { useFilter } from '../context/FilterProvider';

export interface DashboardProps {
  loading: boolean;
  data: any;
  error: any;
}
/**
 * Custom hook for fetching and managing data for rendering a dashboard.
 *
 * @returns {DashboardProps & { getData: (id: number) => void }} Object containing loading state, data, error, and a getData function.
 */
const useRenderData = () => {
  /**
   * The base URL obtained from the AppProvider context.
   */
  const { baseUrl, elementsEndpoint } = useData();

  /**
   * The filters obtained from the FilterProvider context.
   */
  const { filters } = useFilter();

  /**
   * The URL used for fetching data.
   */
  const url = `${baseUrl}${elementsEndpoint}`;
  // const url = `${baseUrl}/msurvey/api/chart/generateChart`;

  /**
   * The response state object, containing loading state, data, and error.
   */
  const [response, setResponse] = useState<DashboardProps>({
    loading: true,
    data: {},
    error: null,
  });

  /**
   * The ID of the current request.
   */
  const [reqId, setReqId] = useState<null | number>(null);

  /**
   * Function for fetching data based on an ID.
   *
   * @param {number} id - The ID of the dashboard element.
   * @returns {Promise<void>}
   */
  const getData = async (id: number) => {
    setResponse(prev => ({ ...prev, loading: true }));
    setReqId(id);
    try {
      const { data } = await axios({
        url,
        method: 'POST',
        data: {
          data: {
            type: 'dashboardElements',
            attributes: {
              dashboardElement: {
                id,
              },
            },
          },
        },
      });

      setResponse(prev => ({ ...prev, data }));
    } catch (error) {
      setResponse(prev => ({ ...prev, error }));
    } finally {
      setResponse(prev => ({ ...prev, loading: false }));
    }
  };

  /**
   * Function for refetching data based on an ID or filters.
   *
   * @param {number|null} id - The ID of the dashboard element or null if not required.
   * @returns {Promise<void>}
   */
  const refetchData = async (id: number | null) => {
    try {
      const { data } = await axios({
        url,
        method: 'POST',
        data: {
          data: {
            type: 'dashboardElements',
            attributes: {
              dashboardElement: {
                id,
              },
              filter: filters.filters,
            },
          },
        },
      });

      setResponse(prev => ({ ...prev, data }));
    } catch (error) {
      setResponse(prev => ({ ...prev, error }));
    } finally {
      setResponse(prev => ({ ...prev }));
    }
  };

  /**
   * Flag indicating whether filtering is required or not.
   */
  const needToFilter = useMemo(() => filters, [
    filters.filters,
    filters.isFilter,
  ]);

  /**
   * useEffect hook to refetch data when filters change.
   */
  useEffect(() => {
    if (needToFilter.isFilter) {
      refetchData(reqId);
    }
  }, [needToFilter]);

  /**
   * Return the response state object and the getData function.
   */
  return { ...response, getData };
};

export default useRenderData;
