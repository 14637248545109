import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = () => {
    const series = [
        {
            name: 'Series 1',
            data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
        }
    ];

    const options = {
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
        }
    };

    return <ReactApexChart options={options} series={series} height={'100%'} type="line" />;
};

export default LineChart;
