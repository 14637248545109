export const aggFn = [
    {label: 'Count', value: 'count'},
    {label: 'AVG', value: 'avg'},
    {label: 'Sum', value: 'sum'}
];

export const singleSeriesCharts = ['pie', 'donut', 'radar', 'heatmaps', 'treemap', 'card'];

export const allCharts = [
    'line',
    'pie',
    'area',
    'bar',
    'column',
    'donut',
    'radar',
    'scatter',
    'bubble',
    'heatmap',
    'treemap',
    'range',
    'candlestick',
    'boxplot'

]

export const filterTypes = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE_RANGE: 'date-range',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    DROPDOWN: 'dropdown',
    DATE: 'date',
    TIME: 'time',
    DROPDOWN_WITH_CHECKBOX: 'dropdown-with-checkbox',
};

export const textColorSwatches = [
    '#000000',
    '#333333',
    '#4D4D4D',
    '#666666',
    '#808080',
    '#999999',
    '#B3B3B3',
    '#ABB8C3',
    '#cccccc',
    '#FFFFFF',
]

export const twitterSwatches = [
    '#FF6900',
    '#FCB900',
    '#7BDCB5',
    '#00D084',
    '#8ED1FC',
    '#0693E3',
    '#ABB8C3',
    '#EB144C',
    '#F78DA7',
    '#9900EF'
]