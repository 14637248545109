import { Box } from '@mui/system';
import React, { ReactNode } from 'react';
import AppContainer from '../components/AppContainer';
import CardTitle from '../components/CardTitle';
import SubCard from '../components/SubCard';
type Props = {
  loading?: boolean;
  error?: any;
  json?: any;
  title: string;
  height?: string | number;
  children: ReactNode;
};

const ChartWrapper: React.FC<Props> = ({
  loading = false,
  error,
  title,
  children,
  height = 550,
  json,
}) => (
  <AppContainer loading={loading} error={error} json={json}>
    {!loading && !error && (
      <SubCard>
        <Box
          sx={{
            // minHeight: Number(height) + 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardTitle title={title} textAlign={'center'} />
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'grid',
              alignItems: 'center',
            }}
          >
            {!loading && children}
          </Box>
        </Box>
      </SubCard>
    )}
  </AppContainer>
);

export default ChartWrapper;
