import { Grid, Typography } from '@mui/material';
import React from 'react';
import FilterComponents from './Filter/FilterComponents';
import Tab from './Tab';
import AppContainer from './components/AppContainer';
import RenderComponents from './components/RenderComponents';
import { useData } from './context/AppProvider';

const App = () => {
  const { response } = useData();

  const { data, loading, error } = response;

  return (
    <>
      <AppContainer loading={loading} error={error}>
        {!error && data.length === 0 && !loading ? (
          <Typography
            component={'h1'}
            textAlign={'center'}
            color={'red'}
            py={5}
            fontSize={25}
            fontWeight={800}
          >
            No data available
          </Typography>
        ) : (
          <>
            <Tab>
              <FilterComponents />
            </Tab>
            <Grid container spacing={3}>
              {data?.elements?.map((item, index: number) => (
                <Grid
                  item
                  xs={item?.containerConfig?.layout?.media?.xs}
                  md={item?.containerConfig?.layout?.media?.md}
                  key={index}
                >
                  <RenderComponents item={item} />
                </Grid>
              ))}
              {/* <Grid item xs={12}>
                <ReactTable />
              </Grid> */}
            </Grid>
          </>
        )}
      </AppContainer>
    </>
  );
};

export default App;
