import React from 'react';
import Cards from '../Cards/Cards';
import Charts from '../Charts/Charts';
import TableWrapper from '../ReactTable/TableWrapper';
import Sections from '../Sections/Sections';
import { COMPONENT_TYPE } from '../constants/Types';

const RenderComponents = ({ item }: any) => {
  const contentTypes: any = {
    [COMPONENT_TYPE.CHART]: <Charts item={item} />,
    [COMPONENT_TYPE.CARD]: <Cards item={item} />,
    [COMPONENT_TYPE.TABLE]: <TableWrapper items={item} />,
    [COMPONENT_TYPE.SECTION]: <Sections items={item.childs} />,
  };

  return <>{contentTypes[item.type] ?? <></>}</>;
};

export default RenderComponents;
