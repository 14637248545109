import { InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
const FormikDateRangeField = ({ name, label }: any) => {
  const { setFieldValue } = useFormikContext();

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();

    let month: any = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;

    let day: any = date.getDate();
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  const [date, setDate] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel id={name}>{label}</InputLabel>

      <DateRange
        ranges={[date]}
        onChange={(object: any) => {
          setDate(object.selection);
          setFieldValue(name, [
            formatDate(object.selection.startDate),
            formatDate(object.selection.endDate),
          ]);
        }}
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
      />
    </Box>
  );
};
export default FormikDateRangeField;
