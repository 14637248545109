import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import useRenderData from '../hooks/useRenderData';
import getScreenHeight from '../utils/getScreenHeight';
import ChartWrapper from './ChartWrapper';

const BasicChart = ({ chartData }: any) => {
  const { data, getData, loading, error } = useRenderData();
  useEffect(() => {
    if (chartData.id) {
      getData(chartData.id);
    }
  }, []);

  const options = {
    xaxis: {
      categories: data?.categories ?? [],
    },
    dataLabels: {
      formatter: function(val, opt) {
        if (chartData?.contentDetails?.showAsPercentage && series?.length > 0) {
          const totalSum = series[0].data.reduce(
            (acc, value) => acc + value,
            0
          );
          return `${totalSum > 0 ? ((val / totalSum) * 100).toFixed(2) : 0}%`;
        }

        return val;
      },
    },
  };
  let series = data?.data ?? [];

  return (
    <ChartWrapper
      title={chartData.contentDetails?.title}
      loading={loading}
      error={error}
      height={getScreenHeight(chartData)}
    >
      <Chart
        options={options}
        series={series}
        height={getScreenHeight(chartData)}
        type={chartData?.contentDetails?.type}
      />
    </ChartWrapper>
  );
};

export default BasicChart;
