import { Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import { useField } from 'formik';
import React from 'react';

const FormikMultiCheckbox = ({ label, name, options, ...rest }: any) => {
  const [field, meta, helpers] = useField({ name });
  const { value } = field;
  const { setValue } = helpers;

  const handleChange = event => {
    const { checked, value: optionValue } = event.target;
    const newValue = checked
      ? [...value, optionValue]
      : value.filter((v: any) => v !== optionValue);
    setValue(newValue);
  };

  return (
    <Box sx={{ mb: 2 }} role="group" aria-labelledby={name}>
      <InputLabel id={name}>{label}</InputLabel>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              name={`${name}.${option.value}`}
              value={option.value}
              checked={value.includes(option.value)}
              onChange={handleChange}
              {...rest}
            />
          }
          label={option.label}
        />
      ))}
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </Box>
  );
};

export default FormikMultiCheckbox;
