import React, { createContext, useContext, useState } from 'react';
import { ConfigInterface } from '..';
import useDashboard, { DashboardProps } from '../hooks/useDashboard';
import useModal, { ModalProps } from '../hooks/useModal';

/**
 * Represents the properties of the app context.
 */
interface AppContextProps<T> {
  baseUrl: T;
  elementsEndpoint: T;
  id: T;
  response: DashboardProps;
  showLoading: boolean;
  modal: ModalProps;
  filters: any[];
  setFilters: any;
}

/**
 * The app context.
 */
export const AppContext = createContext<AppContextProps<any> | null>(null);

/**
 * Represents the properties of the app provider.
 */
interface AppProviderProps<T> {
  initialData?: T;
  children: React.ReactNode;
  showLoading?: boolean;
  config: ConfigInterface;
}

/**
 * The app provider component.
 *
 * @param {AppProviderProps<T>} props - The props for the app provider.
 * @returns {JSX.Element} The app provider component.
 */
export function AppProvider<T>({
  initialData,
  children,
  showLoading = false,
  config,
}: AppProviderProps<T>) {
  /**
   * Fetch the dashboard data using the useDashboard hook.
   */
  const response = useDashboard(
    config.baseUrl + config.dashboardEndpoint,
    config?.id as string,
    config?.route as string
  );

  /**
   * Initialize the modal using the useModal hook.
   */
  const modal = useModal();

  /**
   * Extract the base URL and ID from the config.
   */
  const baseUrl = config.baseUrl;
  const elementsEndpoint = config.elementsEndpoint;
  const id = config.id;

  /**
   * State to store the filters.
   */
  const [filters, setFilters] = useState<any[]>([]);

  /**
   * Render the app provider with the provided children.
   */
  return (
    <AppContext.Provider
      value={{
        baseUrl,
        elementsEndpoint,
        id,
        response,
        showLoading,
        modal,
        filters,
        setFilters,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

/**
 * Custom hook to access the app context.
 *
 * @returns {AppContextProps<any>} The app context.
 * @throws {Error} If used outside the AppProvider component.
 */
export const useData = () => {
  /**
   * Retrieve the app context.
   */
  const context = useContext(AppContext);

  /**
   * Throw an error if the hook is used outside the AppProvider component.
   */
  if (!context) {
    throw new Error('useData must be used within an AppProvider');
  }

  /**
   * Return the app context.
   */
  return context;
};
