import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import useRenderData from '../hooks/useRenderData';
import getScreenHeight from '../utils/getScreenHeight';
import ChartWrapper from './ChartWrapper';

const PieChart = ({ chartData }: any) => {
  const { data, getData, loading, error } = useRenderData();
  useEffect(() => {
    if (chartData.id) {
      getData(chartData.id);
    }
  }, []);

  const series = data?.data?.[0]?.data ?? [];
  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    labels: data?.categories ?? [],
  };

  return (
    <ChartWrapper
      title={chartData.contentDetails?.title}
      loading={loading}
      error={error}
      height={getScreenHeight(chartData)}
    >
      <Chart
        options={options}
        series={series}
        height={getScreenHeight(chartData)}
        type={chartData?.contentDetails?.type}
      />
    </ChartWrapper>
  );
};

export default PieChart;
