import {DatasourceType} from './interface';
import axios from "axios";

export const fetchDatasources = async (baseURL: string, searchString: string) => {
    try {
        const response = await axios.post(`${baseURL}datasource/getAllTabs`, {
            data: {
                type: 'datasources',
                attributes: {
                    datasource: {
                        id: ''
                    },
                    searchString: searchString,
                    updateFlag: false,
                    pageSize: 20
                }
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDatasourceDetails = async (baseURL: string, selectedDatasource: DatasourceType) => {
    try {
        const response = await axios.post(`${baseURL}datasource/getdetails`, {
            data: {
                type: 'datasources',
                attributes: {
                    datasource: {...selectedDatasource},
                    searchString: '',
                    updateFlag: false
                }
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const saveDashboardConfiguration = async (baseURL: string, payload: any) => {
    try {
        const response = await axios.post(`${baseURL}dashboard/add`, {
            data: payload
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
