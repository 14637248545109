import { Grid } from '@mui/material';
import React from 'react';
import RenderComponents from '../components/RenderComponents';
import SubCard from '../components/SubCard';

const Sections = ({ items }) => {
  return (
    <SubCard p={2}>
      <Grid container spacing={3}>
        {items?.map((item, index: number) => (
          <Grid
            item
            xs={item?.containerConfig?.layout?.media?.xs}
            md={item?.containerConfig?.layout?.media?.md}
            key={index}
          >
            <RenderComponents item={item} />
          </Grid>
        ))}
      </Grid>
    </SubCard>
  );
};

export default Sections;
