import {ButtonGroup, Card, Grid, IconButton} from '@mui/material';
import React from 'react';
import {CardContentDataType, GridLayoutType} from './interface';
import {IconX} from '@tabler/icons';
import DashboardCardContent from './CardContent';
import AddComponentPopover from './Popover';
import ContentSettingsDrawer from './ContentSettings';
import {Layout} from "react-grid-layout";

interface DashboardCardProps {
    item: Layout;
    cardContentData?: CardContentDataType[] | null;
    onRemoveCard: (i: string) => void;
    children?: React.ReactNode;
    onAddContentInCard: (i: string, type: string, index: number) => void;
    gridLayoutType: GridLayoutType;
}

const DashboardCard = ({item, onRemoveCard, cardContentData}: DashboardCardProps) => {
    const i = item.i;

    const contentIndex = cardContentData ? cardContentData.findIndex((item) => item.i === i) : -1;

    const type = cardContentData && contentIndex !== -1 ? cardContentData[contentIndex].type : '';

    return (
        <Card key={i} data-grid={item} style={{position: 'relative', borderRadius: 5}}>
            <div style={{width: '100%', height: '100%'}}>
                <div style={{margin: '.5rem 0 .5rem .5rem'}}>
                    <Grid container>
                        <AddComponentPopover item={i} contentIndex={contentIndex}/>
                        <ContentSettingsDrawer itemIndex={i} contentIndex={contentIndex} type={type}/>
                    </Grid>
                </div>

                <div style={{height: 'calc(100% - 56px)'}}>
                    {type !== '' ? <DashboardCardContent contentType={type} contentIndex={contentIndex}/> : ''}
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    right: '.5rem',
                    top: '.5rem',
                    cursor: 'pointer'
                }}
            >
                <ButtonGroup>
                    <IconButton onClick={() => onRemoveCard(i)}>
                        <IconX/>
                    </IconButton>
                </ButtonGroup>
            </div>
        </Card>
    );

};

export default DashboardCard;
