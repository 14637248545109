/**
 * Calculates the screen height based on the provided chart data.
 *
 * @param {any} chartData - The data of the chart.
 * @returns {number} The calculated screen height.
 */
const getScreenHeight = (chartData: any): number => {
  /**
   * Calculate the screen height based on the chart data.
   */
  const screenHeight = Number(
    (window.innerWidth / chartData?.containerConfig?.layout?.column) *
      chartData?.containerConfig?.layout?.height
  );

  /**
   * Return the calculated screen height.
   */
  return screenHeight;
};

export default getScreenHeight;
