import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Box } from '@mui/system';
import { useField } from 'formik';

interface SelectOption {
  label: string;
  value: string;
}

interface FormikSelectFieldProps {
  label: string;
  name: string;
  options: SelectOption[];
}

const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
  label,
  name,
  options,
  ...rest
}) => {
  const [field, meta] = useField(name);

  return (
    <Box sx={{ mb: 2 }}>
      <FormControl
        sx={{ mt: 1 }}
        size="small"
        fullWidth
        error={meta.touched && Boolean(meta.error)}
      >
        <InputLabel id={`${name}`}>{label}</InputLabel>
        <Select
          id={name}
          labelId={`${name}`}
          name={name}
          value={field.value}
          label={label}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...rest}
        >
          {!options.length && (
            <MenuItem disabled value={''}>
              No options
            </MenuItem>
          )}
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </FormControl>
    </Box>
  );
};

export default FormikSelectField;
