import { typesObject } from '../constants/Types';

/**
 * Represents a filter item.
 */
interface FilterItem {
  type: string;
  alias: string;
  source: {
    id: number;
    name: string;
    dbName: string;
    schema: string;
    source: string;
  };
  data?: string[];
}

/**
 * Represents a condition.
 */
interface Condition {
  op: string;
  val: string | string[];
}

/**
 * Represents a filter item with an expected structure.
 */
interface FilterExpectedItem extends FilterItem {
  source: {
    id: number;
    name: string;
    dbName: string;
    schema: string;
    source: string;
    cond: Condition;
  };
}

/**
 * Represents the form data.
 */
type FormDataType = {
  [key: string]: string | string[];
};

/**
 * Generates the expected filter items based on the filter and form data.
 *
 * @param {FilterItem[]} filter - The filter items.
 * @param {FormDataType} formData - The form data.
 * @returns {FilterExpectedItem[]} The expected filter items.
 */
export function generateForm(
  filter: FilterItem[],
  formData: FormDataType
): FilterExpectedItem[] {
  /**
   * Map each filter item to its expected structure based on the form data.
   */
  const filterExpected: FilterExpectedItem[] = filter.map(item => {
    const { type, source } = item;
    const data = formData[source.name];

    let cond: Condition | null = null;
    if (
      type === typesObject.CHECKBOX ||
      type === typesObject.DROPDOWN_WITH_CHECKBOX
    ) {
      cond = {
        op: 'in',
        val: data as string[],
      };
    } else if (type === typesObject.DATE_RANGE) {
      cond = {
        op: 'Between',
        val: (data as string[]).join(' and '),
      };
    } else {
      cond = {
        op: '=',
        val: data as string,
      };
    }

    return {
      ...item,
      source: {
        ...source,
        cond,
      },
    };
  });

  /**
   * Return the expected filter items.
   */
  return filterExpected;
}
