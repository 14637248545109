import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";

import { useField } from "formik";

const FormikRadioField = ({ label, name, options, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <FormControl sx={{ mb: 3 }} component="fieldset" {...rest}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup row name={name} value={field.value} onChange={field.onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </FormControl>
  );
};

export default FormikRadioField;
