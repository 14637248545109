import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";

const StoryCard = () => {
    const backgroundColor = [blue[100]];
    return (
        <Box
            sx={{
                borderRadius: '1rem',
                margin: '0 1rem',
                height: 'calc(100% - 1rem)',
                background: backgroundColor[Math.floor(Math.random() * backgroundColor.length)]
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems={'flex-end'}
                sx={{height: '100%', paddingRight: '2rem'}}
            >
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        Total sales (BDT)
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h3" sx={{fontWeight: 'bold'}}>
                        150,570
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    );
};

export default StoryCard;