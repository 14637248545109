import { Box } from '@mui/material';
import React from 'react';

const Loader = () => (
  <Box
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    height={'100vh'}
  >
    <svg
      width={120}
      height={120}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.spinner_7WDj{transform-origin:center;animation:spinner_PBVY .75s linear infinite}@keyframes spinner_PBVY{100%{transform:rotate(360deg)}}',
        }}
      />
      <path
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity=".25"
      />
      <circle className="spinner_7WDj" cx={12} cy="2.5" r="1.5" />
    </svg>
  </Box>
);

export default Loader;
