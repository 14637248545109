import React, { createContext, useContext, useState } from 'react';
/**
 * Represents the properties of the filter context.
 */
interface FilterContextProps<T> {
  filters: {
    isFilter: boolean;
    filters: any[];
  };
  setFilters: any;
}

/**
 * The filter context.
 */
export const FilterContext = createContext<FilterContextProps<any> | null>(
  null
);

/**
 * Represents the properties of the filter provider.
 */
interface FilterProviderProps<T> {
  children: React.ReactNode;
}

/**
 * The filter provider component.
 *
 * @param {FilterProviderProps<T>} props - The props for the filter provider.
 * @returns {JSX.Element} The filter provider component.
 */
export function FilterProvider<T>({ children }: FilterProviderProps<T>) {
  /**
   * State to store the filters.
   */
  const [filters, setFilters] = useState<any>({
    isFilter: false,
    filters: [],
  });

  /**
   * Render the filter provider with the provided children.
   */
  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

/**
 * Custom hook to access the filter context.
 *
 * @returns {FilterContextProps<any>} The filter context.
 * @throws {Error} If used outside the FilterProvider component.
 */
export const useFilter = () => {
  /**
   * Retrieve the filter context.
   */
  const context = useContext(FilterContext);

  /**
   * Throw an error if the hook is used outside the FilterProvider component.
   */
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  /**
   * Return the filter context.
   */
  return context;
};
