import { Box, TextField } from '@mui/material';
import React from 'react';

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Box>
      <TextField
        label="Search"
        variant="outlined"
        value={value}
        onChange={e => setValue(e.target.value)}
        size="small"
        sx={{ fontSize: '0.8rem' }}
      />
    </Box>
  );
}

export default DebouncedInput;
