import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useData } from '../context/AppProvider';
import Loader from './Loader/Loader';
import SubCard from './SubCard';
type Props = {
  loading: boolean;
  children: ReactNode;
  error?: any;
  json?: any;
};

const AppContainer: React.FC<Props> = ({
  loading = false,
  error = null,
  children,
  json = null,
}) => {
  const { showLoading, response } = useData();
  const wrapper = (
    <>
      {json && <pre>{JSON.stringify(json, null, 2)}</pre>}
      {error && (
        <SubCard>
          <Typography
            component={'h1'}
            textAlign={'center'}
            color={'red'}
            py={5}
            fontSize={25}
            fontWeight={800}
          >
            Something went wrong
          </Typography>
        </SubCard>
      )}
      <>{!error && children}</>
    </>
  );

  if (showLoading || response.loading) {
    return (
      <>
        {loading || response.loading ? (
          <>
            <Loader />
          </>
        ) : (
          wrapper
        )}
      </>
    );
  }

  return wrapper;
};

export default AppContainer;
