import { Box, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect } from 'react';
import AppContainer from '../components/AppContainer';
import useRenderData from '../hooks/useRenderData';

const StoryCard = ({ cardData }: any) => {
  const backgroundColor = [green[100]];
  const { data, getData, loading, error } = useRenderData();
  useEffect(() => {
    if (cardData?.id) {
      getData(cardData?.id);
    }
  }, []);
  return (
    <AppContainer loading={loading} error={error}>
      {!loading && !error && (
        <>
          <Box
            sx={{
              borderRadius: '1rem',
              height: '100%',
              background: backgroundColor,
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems={'flex-end'}
              sx={{ height: '100%', paddingRight: '2rem', py: '20px' }}
            >
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {cardData?.contentDetails?.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                  {data?.data?.toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </AppContainer>
  );
};

export default StoryCard;
