import React from 'react';
import { CARD_TYPE } from '../constants/Types';
import StoryCard from './StoryCard';

const Cards = ({ item }: any) => {
  const cardsComponent: any = {
    [CARD_TYPE.CARD]: <StoryCard cardData={item} />,
  };

  return <>{cardsComponent[item.contentDetails.type] ?? <></>}</>;
};

export default Cards;
