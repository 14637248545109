import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Container, Drawer } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";

import React, { ReactNode } from "react";
import useModal from "./hooks/useModal";

const Tab: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { show, handleShow, handleClose } = useModal();
  const theme = useTheme();
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "end", py: 2, px: 3 }}>
        <Button variant="contained" onClick={handleShow}>
          Filter
        </Button>
      </Box>
      <hr />
      <br />

      <Drawer
        sx={{
          width: 400,

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 400,
            boxSizing: "border-box",
            boxShadow: "-5px 0 5px -5px #333",
          },
        }}
        variant="persistent"
        anchor="right"
        open={show}
      >
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Container sx={{ py: 4 }}>{children}</Container>
      </Drawer>
    </Box>
  );
};

export default Tab;
