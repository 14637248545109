import { useField } from 'formik';
import React from 'react';

import { Checkbox, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';

const FormikMultiSelectField = ({ label, name, options, ...rest }) => {
  const [field, , helpers] = useField(name);

  const handleChange = event => {
    const { value } = event.target;
    helpers.setValue(value);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel id={`${name}`}>{label}</InputLabel>
      <FormControl fullWidth sx={{ mt: 1 }} size="small">
        <Select
          id={name}
          multiple
          value={field.value}
          onChange={handleChange}
          renderValue={selected => selected.join(', ')}
          {...rest}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={field.value.indexOf(option.label) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default FormikMultiSelectField;
