import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import FormikDateRangeField from '../components/FormComponents/FormikDateRangeField';
import FormikMultiCheckbox from '../components/FormComponents/FormikMultiCheckbox';
import FormikMultiSelectField from '../components/FormComponents/FormikMultiSelectField';
import FormikRadioField from '../components/FormComponents/FormikRadioField';
import FormikSelectField from '../components/FormComponents/FormikSelectField';
import FormikTextField from '../components/FormComponents/FormikTextField';
import { typesObject } from '../constants/Types';
import { useFilter } from '../context/FilterProvider';
import { generateForm } from '../utils/filterExpected';

function generateInitialValues(data: any[]) {
  const obj: any = {};

  data.forEach(({ type, source }) => {
    obj[`${source.name}_________${type}`] = [
      'dropdown-with-checkbox',
      'checkbox',
      'date-range',
    ].includes(type)
      ? []
      : '';
  });

  return obj;
}

function splitObjectKeys(obj: any) {
  const result = {};
  for (const key in obj) {
    const splitKey = key.split('_________');
    const newKey = splitKey[0];
    result[newKey] = obj[key];
  }
  return result;
}
const FilterComponents = () => {
  const { filters, setFilters } = useFilter();

  return (
    <>
      {filters.filters?.length && (
        <Formik
          initialValues={generateInitialValues(filters.filters)}
          onSubmit={(data: any) => {
            setFilters((prev: any) => ({
              ...prev,
              isFilter: true,
              filters: generateForm(filters.filters, splitObjectKeys(data)),
            }));
          }}
        >
          <Form>
            {filters.filters?.map((item: any, index: number) => {
              switch (item.type) {
                case typesObject.CHECKBOX:
                  return (
                    <FormikMultiCheckbox
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      options={
                        item?.data?.map((curElem: string) => ({
                          label: curElem,
                          value: curElem,
                        })) ?? []
                      }
                      key={index}
                    />
                  );
                case typesObject.DATE:
                  return (
                    <FormikTextField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      type={'date'}
                      key={index}
                    />
                  );
                case typesObject.DATE_RANGE:
                  return (
                    <FormikDateRangeField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      key={index}
                    />
                  );
                case typesObject.DROPDOWN:
                  return (
                    <FormikSelectField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      options={
                        item?.data?.map((curElem: string) => ({
                          label: curElem,
                          value: curElem,
                        })) ?? []
                      }
                      key={index}
                    />
                  );
                case typesObject.DROPDOWN_WITH_CHECKBOX:
                  return (
                    <FormikMultiSelectField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      options={
                        item?.data?.map((curElem: string) => ({
                          label: curElem,
                          value: curElem,
                        })) ?? []
                      }
                      key={index}
                    />
                  );
                case typesObject.NUMBER:
                  return (
                    <FormikTextField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      type="number"
                      key={index}
                    />
                  );
                case typesObject.RADIO:
                  return (
                    <FormikRadioField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                      options={
                        item?.data?.map((curElem: string) => ({
                          label: curElem,
                          value: curElem,
                        })) ?? []
                      }
                      key={index}
                    />
                  );
                case typesObject.TEXT:
                  return (
                    <FormikTextField
                      name={`${item.source.name}_________${item.type}`}
                      label={item.alias ?? item.source.name}
                    />
                  );
                case typesObject.TIME:
                  return <></>;

                default:
                  return <></>;
              }
            })}

            <Box
              textAlign="right"
              textTransform={'capitalize'}
              sx={{ marginTop: 2 }}
            >
              <Button type="submit" variant="contained" color="primary">
                submit
              </Button>
            </Box>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default FilterComponents;
