import Pagination from '@mui/material/Pagination';
import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import {
  GroupingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import DebouncedInput from './DebouncedInput';

function Table({ data, columns }) {
  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    table.setPageIndex(value - 1 || 0);
  };

  return (
    <div>
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        pb={2}
      >
        <div className="flex items-center gap-1">
          Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16"
          />
        </div>

        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Items</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={table.getState().pagination.pageSize}
            label="Items"
            onChange={e => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <MenuItem value={pageSize} key={pageSize}>
                {' '}
                Show {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
      </Box> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '0.8rem',
        }}
      >
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Items</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={table.getState().pagination.pageSize}
            label="Items"
            onChange={e => {
              table.setPageSize(Number(e.target.value));
            }}
            sx={{ fontSize: '0.8rem' }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <MenuItem value={pageSize} key={pageSize}>
                Show {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
        />
      </Box>
      <Box sx={{ height: 400, overflow: 'auto' }}>
        <table style={{ fontSize: '0.8rem' }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}

                          {/* TODO: un commit the code for implementing sorting feature */}
                          {/* {header.column.getCanGroup() ? (
                          // If the header can be grouped, let's add a toggle
                          <button
                            {...{
                              onClick: header.column.getToggleGroupingHandler(),
                              style: {
                                cursor: 'pointer',
                                background: 'inherit',
                                border: 'none',
                              },
                            }}
                          >
                            {header.column.getIsGrouped()
                              ? `🛑(${header.column.getGroupedIndex()}) `
                              : `👊 `}
                          </button>
                        ) : null}{' '} */}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        {...{
                          key: cell.id,
                        }}
                      >
                        {cell.getIsGrouped() ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <button
                              {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {
                                  cursor: row.getCanExpand()
                                    ? 'pointer'
                                    : 'normal',
                                },
                              }}
                            >
                              {row.getIsExpanded() ? '👇' : '👉'}{' '}
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}{' '}
                              ({row.subRows.length})
                            </button>
                          </>
                        ) : cell.getIsAggregated() ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          flexRender(
                            cell.column.columnDef.aggregatedCell ??
                              cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        py={2}
      >
        <Box sx={{ display: 'flex', fontSize: '0.8rem' }}>
          <div>Page</div>
          <strong style={{ marginLeft: '5px' }}>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </Box>
        <Pagination
          size="small"
          count={table.getPageCount()}
          page={table.getState().pagination.pageIndex + 1}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
}

export default Table;
