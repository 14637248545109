import React from 'react';
import App from './App';
import { AppProvider } from './context/AppProvider';
import { FilterProvider } from './context/FilterProvider';

/**
 * Configuration object for the DashboardUI component.
 * @property {string} baseUrl - The base URL for API requests.
 * @property {string} dashboardEndpoint - The endpoint for fetching dashboard data.
 * @property {string} id - The ID associated with the dashboard.
 */
export interface ConfigInterface {
  baseUrl: string;
  dashboardEndpoint: string;
  elementsEndpoint: string;
  id?: string;
  route?: string;
}

/**
 * DashboardUI Component
 *
 * Renders the dashboard user interface with the provided configuration.
 *
 * @param {ConfigInterface} props - The configuration object for the DashboardUI component.
 * @param {string} props.baseUrl - The base URL for API requests.
 * @param {string} props.dashboardEndpoint - The endpoint for fetching dashboard data.
 * @param {string} props.elementsEndpoint - The endpoint for fetching dashboard elements.
 * @param {string} props.id - The ID associated with the dashboard.
 * @param {string} props.route - The ROUTE associated with the dashboard.
 * @returns {React.ReactElement} The rendered DashboardUI component.
 */
const DashboardUI: React.FC<ConfigInterface> = ({
  baseUrl,
  dashboardEndpoint,
  id,
  route,
  elementsEndpoint,
}) => {
  return (
    <FilterProvider>
      <AppProvider
        config={{ baseUrl, dashboardEndpoint, id, elementsEndpoint, route }}
      >
        <App />
      </AppProvider>
    </FilterProvider>
  );
};

export default DashboardUI;
