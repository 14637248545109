import axios from 'axios';
import { useEffect, useState } from 'react';
import { useFilter } from '../context/FilterProvider';
export interface DashboardProps {
  loading: boolean;
  data: any;
  error: any;
}
/**
 * Custom hook for fetching and managing dashboard data.
 *
 * @param {string} url - The URL for fetching the dashboard data.
 * @param {string} id - The ID of the dashboard.
 * @returns {DashboardProps} Object containing loading state, data, and error.
 */
const useDashboard = (url: string, id: string, route: string) => {
  /**
   * The response state object, containing loading state, data, and error.
   */
  const [response, setResponse] = useState<DashboardProps>({
    loading: true,
    data: [],
    error: null,
  });

  /**
   * The filter context functions.
   */
  const { setFilters } = useFilter();

  /**
   * Function for fetching dashboard data.
   *
   * @returns {Promise<void>}
   */
  const getData = async () => {
    setResponse(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await axios({
        url,
        method: 'POST',
        data: {
          data: {
            type: 'dashboards',
            attributes: {
              dashboard: {
                id: id ? id : null,
                route: route ? route : null,
              },
              searchString: false,
              updateFlag: false,
            },
          },
        },
      });

      setResponse(prev => ({ ...prev, data: data?.rows[0] ?? [] }));

      setFilters(prev => ({ ...prev, filters: data?.rows[0]?.filter ?? [] }));
    } catch (error) {
      setResponse(prev => ({ ...prev, error }));
    } finally {
      setResponse(prev => ({ ...prev, loading: false }));
    }
  };

  /**
   * useEffect hook to fetch dashboard data when the ID changes.
   */
  useEffect(() => {
    if (id || route) {
      getData();
    } else {
      alert(
        "Please enter a URL as the 'url' parameter and an ID as the 'id' parameter."
      );
    }
  }, [id, route]);

  /**
   * Return the response state object.
   */
  return response;
};

export default useDashboard;
