/**
 * Represents the different types of charts available.
 */
interface ChartTypes {
  LINE: string;
  AREA: string;
  BAR: string;
  COLUMN: string;
  SCATTER: string;
  HEATMAP: string;
  CANDLESTICK: string;
  RADIAL_BAR: string;
  PIE: string;
  DONUT: string;
  TREEMAP: string;
  RANGE_BAR: string;
  BOX_PLOT: string;
  FUNNEL: string;
  PYRAMID: string;
}

/**
 * An object containing the different types of charts available.
 */
export const CHART_TYPES: ChartTypes = {
  LINE: 'line',
  AREA: 'area',
  BAR: 'bar',
  COLUMN: 'column',
  SCATTER: 'scatter',
  HEATMAP: 'heatmap',
  CANDLESTICK: 'candlestick',
  RADIAL_BAR: 'radialBar',
  PIE: 'pie',
  DONUT: 'donut',
  TREEMAP: 'treemap',
  RANGE_BAR: 'rangeBar',
  BOX_PLOT: 'boxPlot',
  FUNNEL: 'funnel',
  PYRAMID: 'pyramid',
};

/**
 * An object containing different component types.
 */
export const COMPONENT_TYPE = {
  CHART: 'chart',
  CARD: 'card',
  SECTION: 'section',
  TABLE: 'table',
};

/**
 * An object containing the card type.
 */
export const CARD_TYPE = {
  CARD: 'card',
};

/**
 * Represents a mapping of types to their corresponding values.
 */
type TypesObject = {
  [key: string]: string;
};

/**
 * An object containing the mapping of types to their corresponding values.
 */
export const typesObject: TypesObject = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE_RANGE: 'date-range',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  TIME: 'time',
  DROPDOWN_WITH_CHECKBOX: 'dropdown-with-checkbox',
};
