import React, { useEffect } from 'react';
import ChartWrapper from '../Charts/ChartWrapper';
import useRenderData from '../hooks/useRenderData';
import ReactTable from './ReactTable';

const TableWrapper = ({ items }) => {
  const { data, getData, loading, error } = useRenderData();
  useEffect(() => {
    if (items?.id) {
      getData(items?.id);
    }
  }, []);

  return (
    <ChartWrapper
      title={items?.contentDetails?.title}
      loading={loading}
      error={error}
    >
      <ReactTable
        data={data?.data ?? []}
        columns={
          data?.headers?.map(item =>
            item?.columns
              ? {
                  header: item.header,
                  accessorKey: item.accessor,
                  columns: item?.columns?.map(nestedItem => ({
                    header: nestedItem.header,
                    accessorKey: nestedItem.accessor,
                  })),
                }
              : {
                  header: item.header,
                  accessorKey: item.accessor,
                }
          ) ?? []
        }
      />
    </ChartWrapper>
  );
};

export default TableWrapper;
