import {
    Autocomplete,
    Button,
    Drawer,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {SelectChangeEvent} from '@mui/material/Select';
import {Box} from '@mui/system';
import {IconSettings, IconX} from '@tabler/icons';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {singleSeriesCharts, textColorSwatches} from './CONST';
import {dashboardLayoutContext} from './Layout';
import {fetchDatasourceDetails, fetchDatasources} from './Service';
import YAxisSelector from './YAxisSelector';
import {DatasourceType, FilterType, SourceColumnDetailsType} from './interface';
import FilterSelector from "./FilterSelector";
import {ColorResult, TwitterPicker} from "react-color";
import {dashboardBuilderContext} from "./index";

interface Props {
    itemIndex: string;
    contentIndex: number;
    type: string;
    children?: React.ReactNode;
}

const ContentSettingsDrawer: React.FC<Props> = ({itemIndex, contentIndex, type}) => {
    const {cardContentData, onUpdateCardContentDataSettings} = useContext(dashboardLayoutContext);
    const {baseURL} = useContext(dashboardBuilderContext);

    const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [textColor, setTextColor] = useState('');
    const [searchString, setSearchString] = useState('');
    const [datasourceSearchResults, setDatasourceSearchResults] = useState<DatasourceType[]>([]);
    const [selectedDatasource, setSelectedDatasource] = useState<DatasourceType | null>();
    const [datasourceColumns, setDatasourceColumns] = useState<DatasourceType[]>([]);
    const [xAxis, setXAxis] = useState('');
    const [xAxisDetails, setXAxisDetails] = useState<SourceColumnDetailsType>({
        column: '',
        aggFn: '',
        isPivoted: false
    });
    const [yAxisDetails, setYAxisDetails] = useState<SourceColumnDetailsType[]>([]);
    const [filterDetails, setFilterDetails] = useState<FilterType[]>([]);
    const [pivotColumn, setPivotColumn] = useState('');

    useEffect(() => {
        if (isSettingsDrawerOpen) {
            fetchDatasources(baseURL, searchString)?.then((datasources) => setDatasourceSearchResults(datasources));
        }
    }, [searchString, isSettingsDrawerOpen]);

    useEffect(() => {
        if (selectedDatasource) {
            fetchDatasourceDetails(baseURL, selectedDatasource).then((datasourceDetails) =>
                setDatasourceColumns(datasourceDetails.sourceDetails.columns)
            );
        }
    }, [selectedDatasource]);


    const toggleSettingsDrawer = (open: boolean) => {
        if (!open) setDatasourceSearchResults([]);
        setSelectedDatasource(null);
        setDatasourceColumns([]);
        setXAxis('');
        setPivotColumn('');
        setIsSettingsDrawerOpen(open);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value as string);
    };

    const handleXAxisChange = (event: SelectChangeEvent) => {
        setXAxis(event.target.value);
        const details = {...xAxisDetails}
        details.column = event.target.value;
        setXAxisDetails(details);

    };

    const handleUpdatingXAxisAlias = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const details = {...xAxisDetails}
        details.alias = event.target.value;
        setXAxisDetails(details);
    };

    const handleChangeBackgroundColor = (color: ColorResult) => {
        setBackgroundColor(color.hex);
    }

    const handleChangeTextColor = (color: ColorResult) => {
        setTextColor(color.hex);
    }

    const onApplySettings = () => {
        const previousSettings = cardContentData![contentIndex];

        const newSettings = {
            ...previousSettings,
            options: {
                type: type,
                title: title,
                backgroundColor: backgroundColor,
                textColor: textColor,
                datasource: {...selectedDatasource},
                columns: {
                    xAxis: xAxisDetails,
                    yAxis: yAxisDetails
                },
                filter: filterDetails
            }
        };
        onUpdateCardContentDataSettings?.(contentIndex, newSettings);
        toggleSettingsDrawer?.(false);

        console.log(newSettings)
    };


    return (
        <>
            {type !== '' ? (
                <IconButton
                    onClick={() => {
                        toggleSettingsDrawer?.(true);
                    }}
                >
                    <IconSettings/>
                </IconButton>
            ) : (
                ''
            )}

            <Drawer anchor={'right'} open={isSettingsDrawerOpen}>
                <div style={{position: 'relative', width: '450px', height: '100%'}}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 'calc(100% - 2rem)',
                            padding: '1rem',
                            borderBottom: '1px solid rgba(4,2,3,0.2)'
                        }}
                    >
                        <Grid container justifyContent={'space-between'}>
                            <IconButton onClick={() => toggleSettingsDrawer?.(false)}>
                                <IconX/>
                            </IconButton>
                            <Typography variant="h5">Settings</Typography>
                        </Grid>
                    </Box>
                    <Box sx={{
                        marginTop: '5rem',
                        marginBottom: '5rem',
                        height: '82%',
                        overflowY: 'scroll',
                        paddingTop: '2rem',
                        paddingLeft: '1rem'
                    }}>
                        <div>
                            <Grid sx={{paddingX: '1rem'}} container justifyContent={'space-between'}>
                                <em>Card no. {itemIndex}</em>
                                <em>Type. {type}</em>
                            </Grid>
                            <List>
                                <ListItem>
                                    <FormControl fullWidth sx={{marginTop: '1rem'}}>
                                        <TextField id="title" label="Title"
                                                   onChange={handleTitleChange} value={title}/>
                                    </FormControl>
                                </ListItem>

                                <ListItem>
                                    <Box sx={{
                                        width: '100%',
                                        marginTop: '.5rem',
                                        textAlign: 'center',
                                        paddingX: '1rem',
                                        paddingY: '1rem',
                                        borderRadius: '.5rem',
                                        border: '1px solid rgba(4,2,3,0.2)',
                                        backgroundColor: `${backgroundColor}`
                                    }}>
                                        <Typography sx={{color: `${textColor}`, fontWeight: '500'}}
                                                    variant="subtitle1">
                                            This is your text color
                                        </Typography>
                                    </Box>
                                </ListItem>

                                <ListItem sx={{marginTop: '1rem',}}>
                                    <Typography variant="subtitle1">
                                        Background Color
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <TwitterPicker width={'100%'} color={backgroundColor}
                                                   onChangeComplete={handleChangeBackgroundColor}/>
                                </ListItem>
                                <ListItem sx={{
                                    marginTop: '1rem',

                                }}>
                                    <Typography variant="subtitle1">
                                        Text Color
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <TwitterPicker width={'100%'} colors={textColorSwatches} color={textColor}
                                                   onChangeComplete={handleChangeTextColor}/>
                                </ListItem>
                                {type !== 'section' && (
                                    <>
                                        <ListItem>
                                            <Autocomplete
                                                fullWidth
                                                sx={{marginTop: '1rem'}}
                                                id="combo-box-datasource"
                                                options={datasourceSearchResults ?? []}
                                                getOptionLabel={(option: DatasourceType) => option.name}
                                                onChange={(_, newValue: DatasourceType | null) => setSelectedDatasource(newValue)}
                                                inputValue={searchString}
                                                onInputChange={(_, newValue: string) => setSearchString(newValue)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Datasource"/>
                                                )}
                                            />
                                        </ListItem>

                                        {datasourceColumns.length !== 0 && selectedDatasource && (
                                            <>
                                                {!['card'].includes(type) &&
                                                    <>
                                                        <Typography variant="subtitle1"
                                                                    sx={{margin: '1rem 0 0 1.5rem '}}>
                                                            X-Axis
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid rgba(3,3,3,0.2)',
                                                                borderRadius: '.5rem',
                                                                margin: '1rem',
                                                                padding: '1rem',
                                                                background: 'rgba(3,3,3,0.2)'
                                                            }}
                                                        >
                                                            <Box sx={{
                                                                background: 'white',
                                                                paddingY: '1rem',
                                                                borderRadius: '.2rem'
                                                            }}>
                                                                <ListItem>
                                                                    <FormControl size={'small'} sx={{width: '100%'}}>
                                                                        <InputLabel
                                                                            id="select-x-axis-label">Source</InputLabel>
                                                                        <Select
                                                                            labelId="select-x-axis-label"
                                                                            id="select-x-axis"
                                                                            value={xAxis}
                                                                            onChange={handleXAxisChange}
                                                                            label="Source"
                                                                            sx={{background: 'white'}}
                                                                        >
                                                                            {datasourceColumns
                                                                                .filter((item) => item['name'] !== pivotColumn)
                                                                                .map((item) => (
                                                                                    <MenuItem
                                                                                        value={item.name}>{item.name}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </ListItem>
                                                                {xAxis &&
                                                                    <ListItem>
                                                                        <FormControl fullWidth
                                                                                     sx={{background: 'white'}}
                                                                        >
                                                                            <TextField id="alias" label="Alias"
                                                                                       onChange={(event) => handleUpdatingXAxisAlias(event)}
                                                                                       value={xAxisDetails.alias}
                                                                                       size={'small'}
                                                                            />
                                                                        </FormControl>
                                                                    </ListItem>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </>
                                                }
                                                <YAxisSelector
                                                    title={type === 'table' || type === 'card' ? 'Columns' : undefined}
                                                    isSingleSeries={singleSeriesCharts.includes(type)}
                                                    xAxis={xAxis}
                                                    datasourceColumns={datasourceColumns}
                                                    yAxisDetails={yAxisDetails}
                                                    setYAxisDetails={setYAxisDetails}
                                                />
                                                <FilterSelector
                                                    datasourceColumns={datasourceColumns}
                                                    filterDetails={filterDetails}
                                                    setFilterDetails={setFilterDetails}
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                            </List>
                        </div>
                    </Box>
                    <Box sx={{position: 'absolute', bottom: 0, padding: '1rem'}}>
                        <Grid container justifyContent={'start'} spacing={1}>
                            <Grid item>
                                <Button variant="contained" onClick={onApplySettings}>
                                    Apply Settings
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => toggleSettingsDrawer?.(false)}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Drawer>
        </>
    );
};

export default ContentSettingsDrawer;
