import React, {ChangeEvent, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    InputLabel,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {aggFn} from './CONST';
import {IconChevronDown} from '@tabler/icons';

import {DatasourceType, SourceColumnDetailsType} from './interface';
import {SelectChangeEvent} from '@mui/material/Select';

interface Props {
    title?: string
    xAxis: string;
    datasourceColumns: DatasourceType[];
    yAxisDetails: SourceColumnDetailsType[];
    setYAxisDetails: (yAxises: SourceColumnDetailsType[]) => void;
    isSingleSeries: boolean;
}

const YAxisSelector: React.FC<Props> = ({
                                            title = 'Y-Axis',
                                            datasourceColumns,
                                            yAxisDetails,
                                            setYAxisDetails,
                                            xAxis,
                                            isSingleSeries
                                        }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [yAxis, setYAxis] = useState<string[]>([]);
    const [pivotedColumns, setPivotedColumns] = useState<string[]>([]);
    const [singleSeriesYAxis, setSingleSeriesYAxis] = useState('');

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        if (event) console.log("accordion")
    };

    const handlePivotedColumnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setPivotedColumns([...pivotedColumns, event.target.value]);
        } else {
            setPivotedColumns(pivotedColumns.filter((axis) => axis !== event.target.value));
        }
    };

    const handleAddYAxis = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setYAxis([...yAxis, event.target.value]);
            setYAxisDetails([
                ...yAxisDetails,
                {
                    column: event.target.value,
                    aggFn: 'count',
                    isPivoted: false,
                    valueColForPivot: '',
                }
            ]);
            setExpanded(event.target.value);
        } else {
            setYAxis(yAxis.filter((axis) => axis !== event.target.value));
            setYAxisDetails(yAxisDetails.filter((axis) => axis.column !== event.target.value));
        }
    };

    const handleSingleSeriesYAxis = (event: SelectChangeEvent) => {
        setSingleSeriesYAxis(event.target.value);

        setYAxisDetails([
            {
                column: event.target.value,
                aggFn: '',
                isPivoted: false,
            }
        ]);
    };

    const handleUpdatingYaxisDetails = (
        column: string,
        property: 'aggFn' | 'isPivoted' | 'valueColForPivot',
        event: SelectChangeEvent<any>
    ) => {
        const updatedArray = yAxisDetails.map((detail) => {
            console.log(pivotedColumns);
            if (detail.column === column) {
                return {
                    ...detail,
                    [property]: event.target.value
                };
            }
            return detail;
        });

        setYAxisDetails(updatedArray);
    };

    const handleUpdatingYaxisPivot = (column: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedArray = yAxisDetails.map((detail) => {
            console.log(pivotedColumns);
            if (detail.column === column) {
                return {
                    ...detail,
                    isPivoted: event.target.checked
                };
            }
            return detail;
        });

        setYAxisDetails(updatedArray);
    };

    const handleUpdatingYAxisAlias = (column: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedArray = yAxisDetails.map((detail) => {
            if (detail.column === column) {
                detail.alias = event.target.value
            }
            console.log(detail)
            return detail;
        });

        setYAxisDetails(updatedArray);
    };


    const aggFnValueConstructor = (column: string) => {
        const aggFn = yAxisDetails.find((axis) => axis.column === column)?.aggFn;
        if (aggFn) return aggFn;
        return '';
    };

    const pivotValueColConstructor = (column: string) => {
        const valueCol = yAxisDetails.find((axis) => axis.column === column)?.valueColForPivot;

        if (valueCol) return valueCol;
        return '';
    };

    const aliasValueConstructor = (column: string) => {
        const alias = yAxisDetails.find((axis) => axis.column === column)?.alias;
        console.log(alias)
        if (alias) return alias;

        return '';
    }

    return (
        <>
            <Typography variant="subtitle1" sx={{margin: '1rem 0 0 1.5rem '}}>
                {title}
            </Typography>

            <Box
                sx={{
                    border: '1px solid rgba(3,3,3,0.2)',
                    borderRadius: '.5rem',
                    margin: '1rem',
                    padding: '1rem',
                    background: 'rgba(3,3,3,0.2)'
                }}
            >
                {isSingleSeries ? (
                    <Box
                        sx={{background: 'white', paddingY: '1rem', borderRadius: '.2rem'}}>
                        <ListItem>
                            <FormControl size={'small'} sx={{width: '100%'}}>
                                <InputLabel id="select-y-axis-label">Source</InputLabel>
                                <Select
                                    labelId="select-y-axis-label"
                                    id="select-y-axis"
                                    value={singleSeriesYAxis}
                                    onChange={handleSingleSeriesYAxis}
                                    label="Source"
                                >
                                    {datasourceColumns.map((item) => (
                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>
                        {singleSeriesYAxis && (
                            <>
                                <ListItem>
                                    <FormControl fullWidth>
                                        <TextField id="alias" label="Alias"
                                                   onChange={(event) => handleUpdatingYAxisAlias(singleSeriesYAxis, event)}
                                                   value={aliasValueConstructor(singleSeriesYAxis)}
                                                   size={'small'}
                                        />
                                    </FormControl>
                                </ListItem>

                                <ListItem>
                                    <FormControl size={'small'} fullWidth>
                                        <InputLabel id={`aggFn-select-label-${singleSeriesYAxis}`}>Aggregate
                                            Function</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id={`aggFn-select-${singleSeriesYAxis}`}
                                            value={aggFnValueConstructor(singleSeriesYAxis)}
                                            label="Aggregate Funtion"
                                            onChange={(event) => handleUpdatingYaxisDetails(singleSeriesYAxis, 'aggFn', event)}
                                        >
                                            {aggFn.map((item) => (
                                                <MenuItem key={item.label} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ListItem>


                            </>
                        )}
                    </Box>
                ) : (
                    <>
                        {datasourceColumns
                            .filter((item) => item['name'] !== xAxis)
                            .map((item) => (
                                <Accordion key={item.name} expanded={expanded === item.name}
                                           onChange={handleAccordionChange(item.name)}>
                                    <AccordionSummary expandIcon={<IconChevronDown/>} aria-controls="panel1bh-content"
                                                      id="panel1bh-header">
                                        <FormControlLabel
                                            control={
                                                <Checkbox value={item.name} onChange={handleAddYAxis}
                                                          checked={yAxis.includes(item.name)}/>
                                            }
                                            label={item.name}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormControl
                                            sx={{marginBottom: '1.5rem'}}
                                            fullWidth>
                                            <TextField id="alias" label="Alias"
                                                       disabled={!yAxis.includes(item.name)}
                                                       onChange={(event) => handleUpdatingYAxisAlias(item.name, event)}
                                                       value={aliasValueConstructor(item.name)}
                                                       size={'small'}
                                            />
                                        </FormControl>

                                        <FormControl size={'small'} fullWidth disabled={!yAxis.includes(item.name)}>
                                            <InputLabel id={`aggFn-select-label-${item.name}`}>Aggregate
                                                Function</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id={`aggFn-select-${item.name}`}
                                                value={aggFnValueConstructor(item.name)}
                                                label="Aggregate Funtion"
                                                onChange={(event) => handleUpdatingYaxisDetails(item.name, 'aggFn', event)}
                                            >
                                                {aggFn.map((item) => (
                                                    <MenuItem key={item.label} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControlLabel
                                            sx={{margin: '1rem 0'}}
                                            disabled={!yAxis.includes(item.name)}
                                            control={
                                                <Checkbox
                                                    value={item.name}
                                                    onChange={(event) => {
                                                        handlePivotedColumnsChange(event);
                                                        handleUpdatingYaxisPivot(item.name, event);
                                                    }}
                                                    checked={pivotedColumns.includes(item.name)}
                                                />
                                            }
                                            label={'Pivot this column'}
                                        />

                                        <FormControl
                                            size={'small'}
                                            fullWidth
                                            disabled={!yAxis.includes(item.name) || !pivotedColumns.includes(item.name)}
                                        >
                                            <InputLabel id={`pivot-value-select-label-${item.name}`}>Value
                                                Column</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id={`pivot-value-select-${item.name}`}
                                                value={pivotValueColConstructor(item.name)}
                                                label="Value Column"
                                                onChange={(event) => handleUpdatingYaxisDetails(item.name, 'valueColForPivot', event)}
                                            >
                                                {datasourceColumns.map((item) => (
                                                    <MenuItem key={item.name} value={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>


                                    </AccordionDetails>
                                </Accordion>
                            ))}
                    </>
                )}
            </Box>
        </>
    );
};

export default YAxisSelector;
