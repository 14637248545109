import React, {ChangeEvent, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {IconChevronDown} from "@tabler/icons";
import FormControl from "@mui/material/FormControl";
import {filterTypes} from "./CONST";
import {DatasourceType, FilterType} from "./interface";
import {SelectChangeEvent} from "@mui/material/Select";

interface Props {
    datasourceColumns: DatasourceType[];
    filterDetails: FilterType[];
    setFilterDetails: (param: FilterType[]) => void;
}

const FilterSelector: React.FC<Props> = ({datasourceColumns, filterDetails, setFilterDetails}) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        if (event) console.log("accordion")
    };

    const handleAddFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedFilters([...selectedFilters, event.target.value]);

            const filter = datasourceColumns.find((source) => source.name === event.target.value);
            console.log(filter)

            if (filterDetails.length) {
                setFilterDetails([
                    ...filterDetails,
                    {
                        source: {...filter},
                        alias: '',
                        type: ''
                    }
                ]);
            } else {
                setFilterDetails([
                    {
                        source: {...filter},
                        alias: '',
                        type: ''
                    }
                ])
            }
            setExpanded(event.target.value);
        } else {
            setSelectedFilters(selectedFilters.filter((sourceName) => sourceName !== event.target.value));
            setFilterDetails(filterDetails.filter((filter) => filter.source.name !== event.target.value));
        }
    };


    const filterTypeValueConstructor = (sourceName: string) => {
        const filter = filterDetails.find((filter) => filter.source.name === sourceName);
        if (filter && filter.type) return filter.type;
        return '';
    };

    const handleUpdatingFilterType = (sourceName: string, event: SelectChangeEvent<any>) => {
        const updatedArray = filterDetails.map((detail) => {
            if (detail.source.name === sourceName) {
                return {
                    ...detail,
                    type: event.target.value
                };
            }
            return detail;
        });

        setFilterDetails(updatedArray);
    }

    const filterAliasValueConstructor = (sourceName: string) => {
        const filter = filterDetails.find((filter) => filter.source.name === sourceName);
        if (filter && filter.alias) return filter.alias;
        return '';
    };


    const handleUpdatingFilterAlias = (sourceName: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedArray = filterDetails.map((detail) => {
            if (detail.source.name === sourceName) {
                return {
                    ...detail,
                    alias: event.target.value
                };
            }
            return detail;
        });

        setFilterDetails(updatedArray);
    }


    return (
        <div>
            <Typography variant="subtitle1" sx={{margin: '1rem 0 0 1.5rem '}}>
                Filter
            </Typography>

            <Box
                sx={{
                    border: '1px solid rgba(3,3,3,0.2)',
                    borderRadius: '.5rem',
                    margin: '1rem',
                    padding: '1rem',
                    background: 'rgba(3,3,3,0.2)'
                }}
            >
                {datasourceColumns
                    .map((source) => (
                        <Accordion key={source.name} expanded={expanded === source.name}
                                   onChange={handleAccordionChange(source.name)}>
                            <AccordionSummary expandIcon={<IconChevronDown/>} aria-controls="panel1bh-content"
                                              id="panel1bh-header">
                                <FormControlLabel
                                    control={
                                        <Checkbox value={source.name} onChange={handleAddFilters}
                                                  checked={selectedFilters.includes(source.name)}/>
                                    }
                                    label={source.name}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl size={'small'} fullWidth
                                             disabled={!selectedFilters.includes(source.name)}
                                             sx={{marginBottom: '1rem'}}
                                >
                                    <InputLabel id={`aggFn-select-label-${source.name}`}>Filter Type</InputLabel>
                                    <Select
                                        labelId="filter-type-select-label"
                                        id={`filter-type-select-${source.name}`}
                                        value={filterTypeValueConstructor(source.name)}
                                        label="Filter Type"
                                        onChange={(event) => handleUpdatingFilterType(source.name, event)}
                                    >
                                        {Object.entries(filterTypes).map(([key, value]) => (
                                            <MenuItem key={key} value={value}>
                                                {key}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField id="alias" label="Alias"
                                               disabled={!selectedFilters.includes(source.name)}
                                               onChange={(event) => handleUpdatingFilterAlias(source.name, event)}
                                               value={filterAliasValueConstructor(source.name)}
                                               size={'small'}
                                    />
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Box>

        </div>
    );
};

export default FilterSelector;