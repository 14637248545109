import {Button, ButtonGroup, Grid, IconButton} from '@mui/material';
import {IconDeviceFloppy, IconLayoutGridAdd} from '@tabler/icons';
import _ from 'lodash';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import ReactGridLayout, {Layout, Responsive, WidthProvider} from 'react-grid-layout';
import DashboardCard from './Card';
import {saveDashboardConfiguration} from './Service';
import {CardContentDataType, GridLayoutType} from './interface';
import {dashboardBuilderContext} from "./index";
import {allCharts} from "./CONST";

const ResponsiveReactGridLayout = WidthProvider(Responsive);


interface DashboardLayoutContextType {
    items?: Layout[] | null;
    cardContentData?: CardContentDataType[] | null;
    isSettingsDrawerOpen?: boolean;
    toggleSettingsDrawer?: (open: boolean) => void;
    onAddContentInCard?: (i: string, type: string, index: number) => void;
    onUpdateCardContentDataSettings?: (index: number, settings: any) => void;
    handleSaveSection?: () => void;
    gridLayoutType?: GridLayoutType;
}

export const dashboardLayoutContext =
    React.createContext<DashboardLayoutContextType>({});

interface Props {
    children?: React.ReactNode;
    className?: string;
    rowHeight?: number;
    initialItems?: number;
    itemMeasurements?: { w: number; h: number };
    gridLayoutType: GridLayoutType;
    sectionIndexInCardContent?: number;
}

const DashboardLayout: React.FC<Props> = ({
                                              className = 'layout',
                                              rowHeight = 100,
                                              initialItems,
                                              itemMeasurements = {w: 4, h: 3},
                                              gridLayoutType,
                                              sectionIndexInCardContent,
                                          }) => {
    let initialItemArray: number[] | null;

    if (initialItems) {
        initialItemArray = Array.from({length: initialItems}, (_, i) => i);
    } else {
        initialItemArray = [];
    }

    const {
        baseURL,
        setDashboardNameError,
        dashboardName,
        dashboardRoute,
        setDashboardRouteError,
        sections,
        setSections
    } =
        useContext(dashboardBuilderContext);

    const [items, setItems] = useState<Layout[] | null>(
        initialItems
            ? initialItemArray.map((i) => ({
                i: i.toString(),
                x: i * itemMeasurements.w,
                y: 0,
                w: itemMeasurements.w,
                h: itemMeasurements.h,
                maxH: Infinity,
                maxW: Infinity,
                isDraggable: true,
                isResizable: true,
            }))
            : null
    );
    const [newCounter, setNewCounter] = useState(0);
    const [layoutConfiguration, setLayoutConfiguration] =
        useState<ReactGridLayout.Layout[]>();
    const [cols, setCols] = useState(12);
    const [cardContentData, setCardContentData] = useState<
        CardContentDataType[] | null
    >();
    const onAddItem = useCallback(() => {
        console.log(`adding n ${newCounter}`);

        if (items) {
            if (gridLayoutType === 'dashboard') {
                setItems([
                    ...items,
                    {
                        i: `n ${newCounter}`,
                        x: items.reduce((acc, item) => acc + item.w, 0) % (cols || 12),
                        y: Infinity, // puts it at the bottom
                        w: itemMeasurements.w,
                        h: itemMeasurements.h,
                        isDraggable: true,
                        isResizable: true,
                        maxH: Infinity,
                        maxW: Infinity,
                    },
                ]);
            }
            if (gridLayoutType === 'section') {
                setItems([
                    ...items,
                    {
                        i: `n ${newCounter}`,
                        x: items.reduce((acc, item) => acc + item.w, 0) % (cols || 12),
                        y: Infinity, // puts it at the bottom
                        w: itemMeasurements.w,
                        h: itemMeasurements.h,
                        isDraggable: true,
                        isResizable: true,
                        maxH: Infinity,
                        maxW: Infinity,
                    },
                ]);
            }
        } else {
            if (gridLayoutType === 'dashboard') {
                setItems([
                    {
                        i: `n ${newCounter}`,
                        x: 0 % (cols || 12),
                        y: Infinity, // puts it at the bottom
                        w: itemMeasurements.w,
                        h: itemMeasurements.h,
                        isDraggable: true,
                        isResizable: true,
                        maxH: Infinity,
                        maxW: Infinity,
                    },
                ]);
            }
            if (gridLayoutType === 'section') {
                setItems([
                    {
                        i: `n ${newCounter}`,
                        x: 0 % (cols || 12),
                        y: Infinity, // puts it at the bottom
                        w: itemMeasurements.w,
                        h: itemMeasurements.h,
                        isDraggable: true,
                        isResizable: true,
                        maxH: Infinity,
                        maxW: Infinity,
                    },
                ]);
            }
        }

        setNewCounter(newCounter + 1);
    }, [cols, items, newCounter]);

    const onRemoveItem = useCallback(
        (i: any) => {
            console.log(`removing ${i}`);
            setItems(_.reject(items, {i: i}));
        },
        [items]
    );

    const onLayoutChange = (
        currentLayout: ReactGridLayout.Layout[],
    ) => {
        setItems(currentLayout);
        setLayoutConfiguration(currentLayout);
    };

    const onBreakpointChange = (newBreakpoint: string, newCols: number) => {
        setCols(newCols);
        console.log({newBreakpoint});
    };

    const onAddContentInCard = (i: string, type: string, index: number) => {
        console.log(`adding ${type}Chart in item ${i}`);

        if (!cardContentData) {
            setCardContentData([
                {
                    i: i,
                    type: type,
                },
            ]);
        } else {
            if (index === -1) {
                setCardContentData([
                    ...cardContentData,
                    {
                        i: i,
                        type: type,
                    },
                ]);
            } else {
                if (cardContentData[index].type !== type) {
                    cardContentData[index].type = type;
                    setCardContentData([...cardContentData]);
                } else {
                    console.log('content with same type already exists');
                }
            }
        }
    };

    const onUpdateCardContentDataSettings = (index: number, settings: any) => {
        const updatedArray = [
            ...cardContentData!.slice(0, index),
            settings,
            ...cardContentData!.slice(index + 1),
        ];
        setCardContentData(updatedArray);
    };

    useEffect(() => {
        if (gridLayoutType === 'section') handleSaveSection?.();
    }, [cardContentData]);


    const handleSaveSection = () => {
        const sectionConfiguration = processGridLayoutData();
        if (sectionConfiguration) {
            setSections?.((prevState) => {
                const foundSection = prevState.find(
                    (item) => item.indexInCardContentData === sectionIndexInCardContent
                );

                if (foundSection) {
                    return prevState.map((item) => {
                        if (item.indexInCardContentData === sectionIndexInCardContent) {
                            return {
                                ...item,
                                sectionDetails: sectionConfiguration,
                            };
                        }
                        return item;
                    });
                } else {
                    // If an object with the given index doesn't exist, create a new object and insert it into the state array
                    const newObject = {
                        indexInCardContentData: sectionIndexInCardContent!,
                        sectionDetails: sectionConfiguration,
                    };

                    return [...prevState, newObject];
                }
            });
        }
    };

    const processGridLayoutData = () => {
        if (items && cardContentData && layoutConfiguration) {
            const dashboardElement = layoutConfiguration
                .map((card, index) => {
                    const cardContent = cardContentData.find(
                        (content) => content.i === card.i
                    );

                    if (
                        !cardContent ||
                        (cardContent.type !== 'section' && !cardContent.options)
                    )
                        return undefined;

                    const element: any = {
                        index: card.i,
                        type: allCharts.includes(cardContent.type) ? 'chart' : cardContent.type,
                        containerConfig: {
                            layout: {
                                column: 12,
                                media: {
                                    xs: 12,
                                    md: card.w,
                                },
                                height: card.h,
                                position: {
                                    x: card.x,
                                    y: card.y,
                                },
                            },
                        },
                    };

                    if (
                        cardContent.type !== 'section' &&
                        cardContent.options &&
                        cardContent.options.datasource &&
                        cardContent.options.columns
                    ) {
                        element.contentDetails = {
                            title: cardContent.options.title ?? undefined,
                            type: cardContent.type,
                            backgroundColor: cardContent.options.backgroundColor,
                            textColor: cardContent.options.textColor,
                            datasource: {
                                dbName: cardContent.options.datasource.dbName,
                                name: cardContent.options.datasource.name,
                                schema: cardContent.options.datasource.schema,
                                type: cardContent.options.datasource.type,
                                id: cardContent.options.datasource.id,
                            },
                            columns: {
                                xAxis: cardContent.options.columns.xAxis,
                                yAxis: cardContent.options.columns.yAxis,
                            },
                            filter: cardContent.options.filter ?? [],
                        };
                    }
                    if (cardContent.type === 'section') {

                        if (sections) {
                            const foundSection = sections.find(
                                (item) => item.indexInCardContentData === index
                            );
                            if (foundSection) {
                                element.dashboardElement =
                                    foundSection.sectionDetails.dashboardElement;
                            }
                        }
                    }
                    return element;
                })
                .filter(Boolean);

            if (gridLayoutType === 'dashboard') {
                return {
                    type: 'dashboard',
                    attributes: {
                        dashboard: {
                            name: dashboardName,
                            route: dashboardRoute,
                        },
                        dashboardElement: dashboardElement,
                        searchString: false,
                        updateFlag: false,
                    },
                };
            }
            if (gridLayoutType === 'section')
                return {dashboardElement: dashboardElement};

        }

        return null;
    };
    const handleSaveDashboard = () => {
        if (!dashboardName) {
            setDashboardNameError?.('A dashboard name is required');
            return;
        }

        if (!dashboardRoute) {
            setDashboardRouteError?.('A dashboard route is required');
            return;
        }

        const dashboardConfig = processGridLayoutData();


        if (dashboardConfig) {
            saveDashboardConfiguration(baseURL, dashboardConfig).then((response) => {
                alert(response);
            });
        }
    };

    return (
        <div>
            <dashboardLayoutContext.Provider
                value={{
                    items,
                    cardContentData,
                    onAddContentInCard,
                    onUpdateCardContentDataSettings,
                    handleSaveSection,
                    gridLayoutType,
                }}
            >
                {gridLayoutType === 'dashboard' ? (
                    <Grid container justifyContent={'space-between'}>
                        <Button
                            variant="text"
                            color="info"
                            onClick={onAddItem}
                            startIcon={<IconLayoutGridAdd/>}
                            style={{marginLeft: '.5rem'}}
                        >
                            Add a card
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveDashboard}
                            startIcon={<IconDeviceFloppy/>}
                            style={{marginRight: '.5rem'}}
                        >
                            Save Dashboard
                        </Button>
                    </Grid>
                ) : (
                    <ButtonGroup style={{marginTop: '.5rem', marginLeft: '.5rem'}}>
                        <IconButton onClick={onAddItem}>
                            <IconLayoutGridAdd/>
                        </IconButton>
                    </ButtonGroup>
                )}

                <ResponsiveReactGridLayout
                    className={className}
                    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                    rowHeight={rowHeight}
                    breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                    onLayoutChange={onLayoutChange}
                    onBreakpointChange={onBreakpointChange}
                    autoSize={true}
                    onDragStart={(
                        items,
                        oldItem,
                        newItem,
                        placeholder,
                        e,
                        element
                    ) => {
                        console.log({items, oldItem, newItem, placeholder, element});

                        e.stopPropagation();
                    }}
                >
                    {items ? (
                        items.map((item) =>
                            DashboardCard({
                                item: item,
                                cardContentData: cardContentData,
                                onRemoveCard: onRemoveItem,
                                gridLayoutType: gridLayoutType,
                                onAddContentInCard: onAddContentInCard,
                            })
                        )
                    ) : (
                        <></>
                    )}
                </ResponsiveReactGridLayout>
            </dashboardLayoutContext.Provider>
        </div>
    );
};

export default DashboardLayout;
