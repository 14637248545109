import React from 'react';
import { Box } from '@mui/material';
import DashboardLayout from '../Layout';
import { orange } from '@mui/material/colors';

interface Props {
    index: number;
}
const Section: React.FC<Props> = ({ index }) => {
    const backgroundColor = orange[50];
    return (
        <Box
            sx={{
                borderRadius: '1rem',
                border: '2px dashed grey',
                margin: '0 1rem',
                height: 'calc(100% - 1rem)',
                background: backgroundColor
            }}
        >
            <DashboardLayout gridLayoutType={'section'} sectionIndexInCardContent={index} />
        </Box>
    );
};

export default Section;
