import React from 'react';
import { CHART_TYPES } from '../constants/Types';
import BasicChart from './BasicChart';
import PieChart from './PieChart';

const Charts = ({ item }: any) => {
  const chartsComponent: any = {
    [CHART_TYPES.BAR]: <BasicChart chartData={item} />,
    [CHART_TYPES.AREA]: <BasicChart chartData={item} />,
    [CHART_TYPES.LINE]: <BasicChart chartData={item} />,
    [CHART_TYPES.COLUMN]: <BasicChart chartData={item} />,
    [CHART_TYPES.PIE]: <PieChart chartData={item} />,
  };

  return <>{chartsComponent[item.contentDetails.type] ?? <></>}</>;
};

export default Charts;
