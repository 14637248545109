import {ButtonGroup, IconButton, Popover} from '@mui/material';
import React, {useContext, useState} from 'react';
import {
    IconChartArcs3,
    IconChartArea,
    IconChartBar,
    IconChartBubble,
    IconChartDots,
    IconChartLine,
    IconChartPie2,
    IconFilePlus,
    IconId,
    IconSquare,
    IconTable
} from '@tabler/icons';
import {dashboardLayoutContext} from './Layout';

interface Props {
    item: string;
    contentIndex: number;
}

const chartTypes = [
    {type: 'bar', icon: <IconChartBar/>},
    {type: 'pie', icon: <IconChartPie2/>},
    {type: 'arc', icon: <IconChartArcs3/>},
    {type: 'line', icon: <IconChartLine/>},
    {type: 'dots', icon: <IconChartDots/>},
    {type: 'bubble', icon: <IconChartBubble/>},
    {type: 'area', icon: <IconChartArea/>},
    {type: 'section', icon: <IconSquare/>},
    {type: 'table', icon: <IconTable/>},
    {type: 'card', icon: <IconId/>},

];

const AddComponentPopover: React.FC<Props> = ({item, contentIndex}) => {
    const {onAddContentInCard} = useContext(dashboardLayoutContext);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [showAddContentButton, setShowAddContentButton] = useState(true);

    const handlePopoverButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'add-content-popover' : undefined;
    return (
        <>
            {showAddContentButton && (
                <IconButton aria-describedby={id} onClick={handlePopoverButtonClick}>
                    <IconFilePlus/>
                </IconButton>
            )}

            <Popover
                id={id}
                open={open && showAddContentButton}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <ButtonGroup sx={{p: 2}}>
                    {chartTypes.map((chartType) => (
                        <IconButton
                            key={chartType.type}
                            onClick={() => {
                                setShowAddContentButton(false);
                                onAddContentInCard?.(item, chartType.type, contentIndex);
                            }}
                        >
                            {chartType.icon}
                        </IconButton>
                    ))}
                </ButtonGroup>
            </Popover>
        </>
    );
};

export default AddComponentPopover;
