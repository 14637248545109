import { Box, Typography } from '@mui/material';
import React from 'react';

type Props = {
  title?: string;
  textAlign?: string;
};

const CardTitle: React.FC<Props> = ({ title = '', textAlign = 'left' }) => (
  <>
    {title && (
      <Box marginBottom={2}>
        <Typography
          component={'h1'}
          fontWeight={800}
          textTransform={'capitalize'}
          color={'black'}
          fontSize={20}
        >
          {title}
        </Typography>
        {/* <Divider /> */}
      </Box>
    )}
  </>
);

export default CardTitle;
