import React, { useMemo } from 'react';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Table from './components/Table';
const Styles = styled.div`
  // padding: 1rem;

  table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  table tr:nth-of-type (even) {
    background-color: #f2f2f2;
  }

  table tr:hover {
    background-color: #ddd;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #444;
    color: white;
  }
`;

function ReactTable({ data = [], columns = [] }) {
  const userColumns = useMemo(() => columns, [columns]);
  const userData = useMemo(() => data, [data]);
  return (
    <Styles>
      {/* <ChartWrapper title="Table"> */}
      <Box sx={{ overflow: 'hidden', py: '10px' }}>
        <Table data={userData} columns={userColumns} />
      </Box>
      {/* </ChartWrapper> */}
    </Styles>
  );
}

export default ReactTable;
