import { Box } from '@mui/material';
import React from 'react';

const SubCard = ({ children, p = 5 }) => {
  return (
    <Box
      p={p}
      sx={{
        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
        border: '1px solid #f9f9f9',
        borderRadius: '8px',
      }}
    >
      {children}
    </Box>
  );
};

export default SubCard;
