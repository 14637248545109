import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { useField } from 'formik';
import React from 'react';

const FormikTextField = ({ name, label, ...otherProps }) => {
  const [field, meta] = useField(name);
  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel id={name}>{label}</InputLabel>
      <TextField
        size="small"
        {...field}
        {...otherProps}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        fullWidth
      />
    </Box>
  );
};

export default FormikTextField;
